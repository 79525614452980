<template>
  <ProfileField ref="section" inline editable name="Город" @submit="submit" :pending="loading">
    <template #default>{{ user.personal?.city_name ?? 'Не указан' }}</template>
    <template #form><ProfileCityForm ref="form" @close="closeForm" /></template>
  </ProfileField>
</template>

<script setup>
import ProfileField from '@/components/user/profile/ProfileField'
import ProfileCityForm from '@/components/user/profile/forms/ProfileCityForm'

const section = ref()
const form = ref()
const user = inject('user')

const closeForm = () => { section.value.editing = false }
const submit = () => form.value?.submit()
const loading = computed(() => !!form.value?.loading)
</script>

<style scoped lang="scss">

</style>
