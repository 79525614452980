<template>
  <CityInput
    v-model="form.city_id"
    :error="errors.city_id"
    :can-deselect="false"
  />
</template>

<script setup>
import useForm from '~/composables/useForm'
import CityInput from '@/components/common/CityInput'
import { storeCity } from '@/api/user'

const emit = defineEmits(['close'])

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')
const {
  form,
  errors,
  submit,
  loading
} = useForm(storeCity, onSuccess)

async function onSuccess () {
  emit('close')
  await updateUserInfo()
}
defineExpose({ loading, submit })

watch(user, value => {
  form.value = {
    city_id: value?.personal?.city_id ?? null
  }
}, { immediate: true })
</script>

<style scoped lang="scss">
.inputs-grid {
  gap: 16px;
}
</style>
