<template>
  <AtomInput
    name="Введите новый E-mail"
    v-model="form.email"
    :error="errors.email"
  />
</template>

<script setup>
import useForm from '~/composables/useForm'
import AtomInput from '@/components/common/AtomInput'
import { updateEmail } from '@/api/user'

const emit = defineEmits(['close'])

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')
const {
  form,
  errors,
  submit,
  loading
} = useForm(updateEmail, onSuccess)

async function onSuccess () {
  emit('close')
  await updateUserInfo()
}
defineExpose({ loading, submit })
</script>

<style scoped lang="scss">

</style>
