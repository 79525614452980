<template>
  <ProfileField ref="section" inline editable name="E-mail" @submit="submit" :pending="loading">
    <template #default>{{ user.new_email ?? user.email }}</template>
    <template #form><ProfileEmailForm ref="form" @close="closeForm" /></template>
    <template #after>
      <ClientOnly><StatusNoticeEmail class="email-notice" /></ClientOnly>
    </template>
  </ProfileField>
</template>

<script setup>
import ProfileField from '@/components/user/profile/ProfileField'
import ProfileEmailForm from '@/components/user/profile/forms/ProfileEmailForm'
import StatusNoticeEmail from '@/components/user/profile/StatusNoticeEmail'

const section = ref()
const form = ref()
const user = inject('user')

const closeForm = () => { section.value.editing = false }
const submit = () => form.value?.submit()
const loading = computed(() => !!form.value?.loading)
</script>

<style scoped lang="scss">
.email-notice {
  margin: 24px 0 0;
}
</style>
