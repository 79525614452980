<template>
  <div class="inputs-grid">
    <AtomInput
      name="Имя"
      v-model="form.first_name"
      :error="errors.first_name"
    />
    <AtomInput
      name="Фамилия"
      v-model="form.last_name"
      :error="errors.last_name"
    />
  </div>
</template>

<script setup>
import useForm from '~/composables/useForm'
import AtomInput from '@/components/common/AtomInput'
import { storeCredentials } from '@/api/user'

const emit = defineEmits(['close'])

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')
const {
  form,
  errors,
  submit,
  loading
} = useForm(storeCredentials, onSuccess)

async function onSuccess () {
  emit('close')
  await updateUserInfo()
}
defineExpose({ loading, submit })

watch(user, value => {
  form.value = {
    first_name: value?.personal?.first_name,
    last_name: value?.personal?.last_name
  }
}, { immediate: true })
</script>

<style scoped lang="scss">
.inputs-grid {
  gap: 16px;
}
</style>
