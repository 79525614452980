<template>
  <div class="inputs-grid">
    <ContactInput
      v-model:type="form.contact_type"
      v-model="form.contact"
      :error-type="errors.contact_type"
      :error="errors.contact"
    />
  </div>
</template>

<script setup>
import useForm from '~/composables/useForm'
import { storeContact } from '@/api/user'
import ContactInput from '@/components/common/ContactInput'

const emit = defineEmits(['close'])

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')
const {
  form,
  errors,
  submit,
  loading
} = useForm(storeContact, onSuccess)

async function onSuccess () {
  emit('close')
  await updateUserInfo()
}
defineExpose({ loading, submit })

watch(user, value => {
  form.value = {
    contact_type: value?.contact_type,
    contact: value?.contact
  }
}, { immediate: true })
</script>

<style scoped lang="scss">
.inputs-grid {
  gap: 16px;
}
</style>
