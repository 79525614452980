<template>
  <div class="inputs-grid">
    <AtomInput
      type="password"
      class="input-col-2"
      name="Текущий пароль"
      v-model="form.current_password"
      :error="errors.current_password"
    />
    <PasswordAtomInput
      class="input-col-2"
      name="Новый пароль"
      v-model="form.new_password"
      :error="errors.new_password"
    />
    <PasswordAtomInput
      class="input-col-2"
      name="Подтвердите пароль"
      v-model="form.new_password_confirmation"
      :error="errors.new_password_confirmation"
    />
  </div>
</template>

<script setup>
import useForm from '~/composables/useForm'
import AtomInput from '@/components/common/AtomInput'
import PasswordAtomInput from '@/components/common/PasswordAtomInput'
import { changePassword } from '@/api/auth'

const emit = defineEmits(['close'])

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')
const {
  form,
  errors,
  submit,
  loading
} = useForm(changePassword, onSuccess)

async function onSuccess () {
  emit('close')
  await updateUserInfo()
}
defineExpose({ loading, submit })

watch(user, value => {
  form.value = {
    first_name: value?.personal?.first_name,
    last_name: value?.personal?.last_name
  }
}, { immediate: true })
</script>

<style scoped lang="scss">
.inputs-grid {
  gap: 16px;
}
</style>
