<template>
  <StatusNotice success v-if="$route.query.email_confirmed === 'true'">
    Ваш новый E-mail успешно подтвержден.
  </StatusNotice>
  <StatusNotice success v-else-if="user.new_email">
    <div>Подтвердите смену E-mail. Мы отправили письмо с подтверждением на {{ user.email }}.<br>Не забудьте проверить папку “Спам”.</div>
    <template #actions>
      <div>
        <BaseButton color-primary @click="resend" :class="{ disabled: !isActive }">Отправить письмо повторно</BaseButton>
        <div class="time" v-if="!isActive">{{ formatTimeMs(delay) }}</div>
      </div>
      <BaseButton transparent @click="cancel" :class="{ pending: cancelling }">Отмена</BaseButton>
    </template>
  </StatusNotice>
</template>

<script setup>
import BaseButton from '@/components/common/BaseButton.vue'
import StatusNotice from '@/components/common/StatusNotice.vue'
import useActionDelay from '~/composables/useActionDelay'
import { cancelUpdateEmail, updateEmail } from '@/api/user'
import { formatTimeMs } from '~/utils/formatMs'
import useForm from '~/composables/useForm'

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')

const {
  action: resend,
  isActive,
  delay
} = useActionDelay(
  () => updateEmail({ email: user.value.new_email }),
  60000,
  { immediate: true, localStorageKey: 'email-notice-delay' }
)

const { submit: cancel, loading: cancelling } = useForm(cancelUpdateEmail, updateUserInfo)
</script>

<style scoped lang="scss">
.time {
  margin-top: 8px;
}
</style>
