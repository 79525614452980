<template>
  <ProfileField ref="section" inline editable name="Имя и фамилия" @submit="submit" :pending="loading">
    <template #default>{{ user.personal?.first_name }} {{ user.personal?.last_name }}</template>
    <template #form><ProfileCredentialsForm ref="form" @close="closeForm" /></template>
  </ProfileField>
</template>

<script setup>
import ProfileField from '@/components/user/profile/ProfileField'
import ProfileCredentialsForm from '@/components/user/profile/forms/ProfileCredentialsForm'

const section = ref()
const form = ref()
const user = inject('user')

const closeForm = () => { section.value.editing = false }
const submit = () => form.value?.submit()
const loading = computed(() => !!form.value?.loading)
</script>

<style scoped lang="scss">

</style>
