<template>
  <main class="user-settings">
    <div class="horiz-container default-grid">
      <div class="content">
        <h1 class="page-title">Настройки</h1>
        <div class="user-settings-list">
          <ProfileCredentials />
          <ProfileCity />
          <ProfileContact v-if="user.is_mentor" />
          <ProfileEmail />
          <ProfilePassword />
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import ProfileCredentials from '@/components/user/profile/ProfileCredentials'
import ProfileCity from '@/components/user/profile/ProfileCity'
import ProfileEmail from '@/components/user/profile/ProfileEmail'
import ProfilePassword from '@/components/user/profile/ProfilePassword'
import ProfileContact from '@/components/user/profile/ProfileContact'

useHead({
  title: 'Настройки'
})

const user = inject('user')
</script>

<style scoped lang="scss">
.page-title {
  margin-bottom: 64px;
  @media (max-width: 920px) {
    margin-bottom: 24px;
  }
}

.content {
  grid-column: 3 / 11;
  @media (max-width: 920px) {
    grid-column: span 12;
  }
}

.user-settings-list {
  :deep(> *:not(:last-child)) {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--color-separator);
  }
}
</style>
